import { t, Trans } from '@lingui/macro'
import { Button, Markdown, Modal } from 'components'
import { ContactForm, Layout, QueryLoader, Section } from 'containers'
import FingerprintBackground from 'icons/fingerprint-large.svg'
import Fingerprint from 'icons/fingerprint.svg'
import Screen from 'icons/hero-screen.svg'
import { NextPage } from 'next'
import getConfig from 'next/config'
import Head from 'next/head'
import React, { useMemo } from 'react'
import { LandingPageQuery, useLandingPageQuery } from 'types/cms'
import { Router } from 'utils/router'
import { api as authAPI } from 'utils/withAuth'
import { LOCALE_CODES, useLocale } from 'utils/withLang'

const baseUrl = getConfig().publicRuntimeConfig.BASE_URL

type LandingPageType = NonNullable<
  NonNullable<LandingPageQuery['landingPage']>['data']
>['attributes']

const HeroSection: React.FC<{
  data: NonNullable<NonNullable<LandingPageType>['hero']>['data']
}> = ({ data }) => {
  const locale = useLocale()
  const registerURL = useMemo(() => authAPI.registerUrl(locale), [locale])

  return (
    <Section background="grey" border="none">
      <div className="flex justify-between flex-col lg:flex-row">
        <div className="max-w-600 pt-32 sm:pt-125">
          <h1 className="mb-24 heading-d2 md:heading-d1">
            {data?.attributes?.title}
          </h1>
          <Markdown className="mb-32 text-20 text-grey-900">
            {data?.attributes?.content}
          </Markdown>
          <Button
            kind="primary"
            data-cy-id="landing.hero.registerButton"
            className="mb-60 px-72 py-16 text-center text-20 hidden sm:inline-block shadow"
            href={registerURL}
          >
            <Trans id="landing.hero.registerButton">Register</Trans>
          </Button>
        </div>
        <div className="relative xl:right-115 w-full max-w-500 h-400 lg:h-700 flex justify-center sm:justify-start items-end">
          <Fingerprint
            className="absolute"
            style={{ top: '30px', bottom: '30px' }}
          />
          <div className="relative sm:left-115 max-w-300 lg:max-w-none flex-shrink select-none">
            <img
              src="/images/mobile-hand.png"
              alt={t`landing.hero.mobileHandAlt`}
            />
            <Screen
              className="absolute top-0"
              style={{
                width: '77%',
                height: '77%',
                marginTop: '2%',
                marginLeft: '0',
              }}
            />
          </div>
          <div className="h-80 absolute bottom-0 left-0 right-0 sm:hidden z-10 -mx-20 bg-gradient-to-t from-grey-200" />
        </div>
        <div className="-mx-20 px-20 bg-grey-200 sm:hidden relative z-10">
          <Button
            kind="primary"
            data-cy-id="landing.hero.registerButton"
            className="my-32 px-72 py-16 text-center block text-20"
            fullWidth
            href={registerURL}
          >
            <Trans id="landing.hero.registerButton">Register</Trans>
          </Button>
        </div>
      </div>
    </Section>
  )
}

const QuickstartSection: React.FC<{
  data: NonNullable<NonNullable<LandingPageType>['quickstart']>['data']
}> = ({ data }) => (
  <Section>
    <div className="py-32 sm:py-100">
      <h2 className="mb-32 sm:mb-64 heading-h1 sm:heading-d2">
        {data?.attributes?.title}
      </h2>
      {data?.attributes?.content && (
        <Markdown className="mb-40">{data?.attributes?.content}</Markdown>
      )}
      <div className="flex lg:flex-col">
        <ol className="flex flex-col lg:flex-row space-y-45 lg:space-y-0 lg:space-x-50">
          {data?.attributes?.subsections?.data?.map((section, i) => (
            <li
              key={section?.attributes?.order}
              className="flex-1 flex lg:flex-col"
            >
              <div className="flex flex-col lg:flex-row">
                <div className="w-64 h-64 rounded-full border border-grey-300 flex-shrink-0 flex justify-center items-center">
                  {section?.attributes?.icon && (
                    <img
                      src={section.attributes?.icon.data?.attributes?.url}
                      className="w-20 h-20"
                      alt=""
                    />
                  )}
                </div>
                {i + 1 !== data.attributes?.subsections?.data?.length && (
                  <div className="border-grey-400 border-l lg:border-l-0 lg:border-t h-full lg:h-0 lg:w-full mt-10 lg:mt-32 ml-32 lg:ml-20 lg:-mr-30 -mb-32 lg:mb-0" />
                )}
              </div>
              <div className="ml-24 lg:ml-0">
                <h3 className="heading-h4 lg:heading-h3 mb-32 mt-20 lg:mt-32">
                  {section?.attributes?.title}
                </h3>
                <Markdown className="text-grey-800">
                  {section?.attributes?.content}
                </Markdown>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  </Section>
)

const ProductsSection: React.FC<{
  data: NonNullable<NonNullable<LandingPageType>['products']>['data']
}> = ({ data }) => (
  <>
    <Section className="bg-grey-200 md:bg-white-100" border="none">
      <div className="py-32 md:py-100 md:flex mb-8">
        <div className="flex-1">
          <h2 className="heading-h1 md:heading-d2 mb-16">
            {data?.attributes?.title}
          </h2>
        </div>
        <div className="h-32 w-100" />
        <Markdown className="flex-1 text-grey-900">
          {data?.attributes?.content}
        </Markdown>
      </div>
    </Section>
    <Section>
      <h3 className="uppercase text-grey-800 text-14 tracking-wider mt-32 md:mb-40">
        <Trans id="landing.products.useCases">Bank iD use cases:</Trans>
      </h3>
      <hr className="hidden md:block" />
      <ul className="mb-32 md:mb-100">
        {data?.attributes?.subsections?.data?.map((section) => (
          <li
            className="py-24 md:py-32 flex flex-col md:flex-row border-b border-grey-300 last:border-0"
            key={section?.attributes?.title}
          >
            <h4 className="flex-1 heading-h3">{section?.attributes?.title}</h4>
            <div className="h-8 w-100" />
            <Markdown className="flex-1 text-grey-900">
              {section?.attributes?.content}
            </Markdown>
          </li>
        ))}
      </ul>
    </Section>
  </>
)

const BanksSection: React.FC<{
  data: NonNullable<NonNullable<LandingPageType>['banks']>['data']
}> = ({ data }) => (
  <Section>
    <div className="py-32 md:py-100">
      <h2 className="heading-h4 md:heading-h3 md:text-center mb-40 md:mb-64">
        {data?.attributes?.title}
      </h2>
      {data?.attributes?.content && (
        <Markdown className="text-grey-900">
          {data.attributes?.content}
        </Markdown>
      )}
      <div className="max-w-900 mx-auto">
        <div className="flex justify-between items-center flex-wrap -mr-60 -mb-50">
          {data?.attributes?.subsections?.data?.map(
            (section) =>
              section?.attributes?.icon && (
                <img
                  src={section.attributes?.icon?.data?.attributes?.url}
                  alt={section.attributes?.title ?? ''}
                  className="mr-60 mb-50 min-w-100 sm:min-w-200 md:min-w-300 lg:min-w-0 object-contain"
                  key={section.attributes?.title}
                  style={{
                    width:
                      section.attributes?.icon?.data?.attributes?.width + 'px',
                    height:
                      section.attributes?.icon.data?.attributes?.height + 'px',
                  }}
                  width={
                    section.attributes?.icon?.data?.attributes?.width ??
                    undefined
                  }
                  height={
                    section.attributes?.icon?.data?.attributes?.height ??
                    undefined
                  }
                />
              )
          )}
        </div>
      </div>
    </div>
  </Section>
)

const ContactSection: React.FC<{
  data: NonNullable<NonNullable<LandingPageType>['contact']>['data']
}> = ({ data }) => (
  <Section
    backgroundImage={
      <FingerprintBackground className="absolute w-full h-full opacity-75" />
    }
  >
    <div className="my-50 p-40 bg-white-100 max-w-600">
      <h2 className="heading-h1 md:heading-d2 mb-24">
        {data?.attributes?.title}
      </h2>
      <Markdown className="text-grey-900 mb-32">
        {data?.attributes?.content}
      </Markdown>
      <Button
        data-cy-id="landing.contactUs.contactUsButton"
        to="/contact-us"
        className="px-50 py-16 text-20 inline-block shadow"
        scroll={false}
      >
        <Trans id="landing.contact.askQuestion">Ask us a question</Trans>
      </Button>
    </div>
  </Section>
)

type LandingPageProps = {
  contactFormModalOpen: boolean
}

const LandingPage: NextPage<LandingPageProps> = ({ contactFormModalOpen }) => {
  const locale = useLocale()
  const landingPageQuery = useLandingPageQuery({
    variables: { locale },
    context: { endpoint: 'cms' },
  })
  const landingPage = landingPageQuery.data?.landingPage?.data?.attributes

  return (
    <>
      <Head>
        <meta property="og:title" content={landingPage?.title ?? undefined} />
        <meta
          property="og:description"
          content={landingPage?.hero?.data?.attributes?.content ?? undefined}
        />
        <meta property="og:image" content={`${baseUrl}/logo.png`} />
        <meta property="og:url" content={`${baseUrl}/`} />
        <meta property="og:site_name" content="Bank iD" />
        <meta property="og:locale" content={LOCALE_CODES[locale]} />
      </Head>
      <Layout title={`${landingPage?.title}`} background="white">
        <QueryLoader queries={[landingPageQuery]}>
          <HeroSection data={landingPage?.hero?.data} />
          <QuickstartSection data={landingPage?.quickstart?.data} />
          <ProductsSection data={landingPage?.products?.data} />
          <BanksSection data={landingPage?.banks?.data} />
          <ContactSection data={landingPage?.contact?.data} />
        </QueryLoader>

        <Modal
          title={<Trans id="landing.contact.contactUs">Contact us</Trans>}
          data-cy-id="landing.contact.contactUs"
          isOpen={contactFormModalOpen}
          onClose={() => Router.pushRoute('/', { scroll: false })}
          maxWidth={450}
        >
          <ContactForm
            source="/"
            onSubmit={() => Router.pushRoute('/', { scroll: false })}
          />
        </Modal>
      </Layout>
    </>
  )
}

LandingPage.getInitialProps = async (ctx) => {
  const contactFormModalOpen = !!ctx.asPath?.match(/contact-us$/)
  return { contactFormModalOpen }
}

export default LandingPage
